
/**
 * @description  Sets the remoteEntry URL of the exercise player (module federation).
 */

declare global {
    interface Window {
        exercisePlayerUrl: string;
    }
}

export function setExercisePlayerRoot() {
    window.exercisePlayerUrl = `${
        window.env.EXERCISE_PLAYER_ROOT
    }/remoteEntry.js?cb=${Date.now()}`;
}
